import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import BenefitList from "../../components/benefitList"
import "../../scss/pages/smartbox.scss"


class SmartBoxPage extends React.Component {
  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll("main h1 span, main h2, main h4, main p, main .btn, main dt, main dd"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace(/®/g, "<sup>&reg;</sup>");
    })

    // Replacing benefits 
    let benefitElements = document.querySelectorAll(".benefit-list-item p, .quote h2"); 
    benefitElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('Reduced waste', "Reduced <span class='d-inline d-md-block'></span> waste");
      el.innerHTML = el.innerHTML.replace('any Planter', "Any&nbsp;Planter");
    })
  }

  render() {
    // Component Content
    const heroContent = get(this, 'props.data.contentfulHero');

    // Images
    const lightningBoltImg = get(this, 'props.data.lightningBoltImg');
    const smartboxTrimbleImg = get(this, 'props.data.smartboxTrimbleImg');
    const smartboxBarImg = get(this, 'props.data.smartboxBarImg');
    const smartboxLineupImg = get(this, 'props.data.smartboxLineupImg');  
    const greenCirclesImg = get(this, 'props.data.greenCirclesImg');
    const contentBenefitList = get(this, "props.data.smartboxBenefitsList")

    return (
      <Layout bodyClass="smartbox">
        <Seo title="SmartBox+ Equipment" description="Own the furrow with SmartBox+ by precisely applying granular inputs at planting." />

        <section className="padding--none">
          <Hero data={heroContent}/>
        </section>

        <BackgroundImage
          tag="section"
          fluid={greenCirclesImg.fluid}
          id="accurate"
        >
          <div className="container">
            <div className="container--width-md bg--white flow">
            <h2>Accurate Down to the Granule</h2>
            <p>SmartBox+™ application system contains both metering and flow-sensing functions that ensure accurate application of SmartBox® inputs at a wide range of rates and speeds. With patented, state-of-the-art inclined brush auger meters, SmartBox+ equipment will put your mind at ease, knowing you’re applying exactly what you need, precisely where you need it — and you have the ability to generate as-applied maps to document applications through the monitor task control.</p>
            </div>
            
          </div>
        </BackgroundImage>

        <section className="bg--gray" id="accurate">
          <div className="container container--width-sm text-center flow--thick">
            <h2>Benefits</h2>
            <p id="benefits-intro">As one of the most advanced closed delivery systems available, the SmartBox+ application system will allow you to increase operational efficiencies and reap the following benefits:</p>
            <BenefitList data={contentBenefitList} />
            <a href="/simpas-applied-solutions/smartbox-inputs/" className="btn">Explore SmartBox Inputs</a>
          </div>
        </section>


        <section>
          <div className="container container--width-sm text-center flow">
            <h2>How It Works</h2>
            <p>The SmartBox+ application system uses SmartBox® containers to apply a variety of products accurately. The improved brush auger meters and the intuitive ISO-based user interface of the SmartBox+ system maintain a closed delivery system to protect farmers and the environment.</p>
            <a href="https://www.simpas.com/userguide/smartboxplus/#/" target="_blank" rel="noreferrer noopener" className="link">Read the SmartBox+ User Guide</a>
          </div>
        </section>
        

        <section className="padding--none">
          <div className="quote">
            <div className="quote__text container bg--gray flow">
              <h2>SmartBox+ Equipment Works with any Planter</h2>
              <p>The SmartBox+ application system is as versatile as it is effective. No matter which planter you use — from Case IH® to John Deere® to Great Plains® to Kinze® to White™ — there’s a bracket to fit it.</p>
              <Img
                alt={smartboxBarImg.description}
                fluid={smartboxBarImg.fluid}
              />
            </div>
            <div className="quote__img">
              <Img
                alt={smartboxLineupImg.description}
                fluid={smartboxLineupImg.fluid}
              />
            </div>
          </div>
        </section>

        

        <section id="powered">
          <div className="container bg--blue-dark">
            <div>
              <div className="row d-flex flex-column-reverse flex-md-row flow">
                <div className="row">
                  <div className="col-12 col-md-2">
                    <Img
                      alt={lightningBoltImg.description}
                      fluid={lightningBoltImg.fluid}
                      className="d-none d-md-block"
                      style={{maxWidth: "100px"}}
                    />
                  </div>
                  <div className="col-12 col-md-10 flow">
                    <h2>Powered by SIMPAS&reg; Technology</h2>
                    <p>Farmers have more options and control with SmartBox+. Apply labeled rates of granular at-plant inputs precisely to every acre, or apply them prescriptively using SIMPAS technology to treat spatially distinct problem areas. Agronomists develop pressure-specific prescriptions using a variety of factors (e.g., soil sampling, historical yield data, elevation, etc.) that are easily uploaded into the ISO-based SmartBox+ control display. Once uploaded, the system takes care of the rest, automatically dispensing the prescribed rate at geo-specific locations in each field.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="sleek">
          <div className="container">
            <div>
              <div className="row d-flex flex-column-reverse flex-md-row flow">
                <div className="row flow">
                  <div className="col-8 col-md-4 col-lg-5 sleek__img">
                    <Img
                      alt={smartboxTrimbleImg.description}
                      fluid={smartboxTrimbleImg.fluid}
                    />
                  </div>
                  <div className="col-12 offset-md-1 col-md-7 col-lg-6 flow">
                    <h2>Sleek and Precise</h2>
                    <p>The SmartBox+ system uses a full-color touchscreen display with industry standard ISO connections and a USB port for easy data transfer. Swath control comes standard, removing the guesswork associated with row shutoffs. The user-friendly, intuitive menu allows you to set rates by individual row and is compatible with 12V and&nbsp;24V.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg--green contact">
          <div className="text-center">
            <h3 className="h2">Ready to Change the Way You Plant?</h3>
            <br />
            <div className="container">
              <div className="adj-buttons">
                <div className="text-center">
                  <a 
                    className="btn" 
                    href="/equipment/equipment-costs/#/smartbox">
                    Estimate Equipment Costs
                  </a>
                </div>
              </div>
              <div className="adj-buttons">
                <div className="text-center">
                  <a className="btn" href="/find-a-provider/#map-container">
                    Find a Dealer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    )
  }
}
export default SmartBoxPage


export const SmartBoxPageQuery = graphql`
  query SmartboxPageQuery {
    contentfulHero(heroTitle: {eq: "SmartBox+™"}) {
      id
      heroTitle
      heroSubtitle
      heroDescription {
        heroDescription
      }
      backgroundImage {
        fluid(maxWidth: 1440, resizingBehavior: PAD, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
        description
      }
    }
    smartboxLineupImg: contentfulAsset(title: {eq: "smartbox-lineup"}) {
      id
      description
      title
      fluid (maxWidth: 768, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid
      }
    }
    heroSmartboxImg: contentfulAsset(title: {eq: "hero-smartbox"}) {
      id
      description
      title
      fluid (maxWidth: 768, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid
      }
    }
    lightningBoltImg: contentfulAsset(title: {eq: "lightning-bolt"}) {
      id
      description
      title
      fluid (maxWidth: 768, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
    greenCirclesImg: contentfulAsset(title: {eq: "bg-green-circles"}) {
      id
      description
      title
      fluid (maxWidth: 1440, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid
      }
    }
    smartboxBenefitsList: contentfulList(listName: { eq: "Smartbox Benefits List" }) {
      id
      listClass
      listItems {
        ... on ContentfulListItem {
          id
          listItemDescription
          listItemImage {
            file {
              url
            }
            description
          }
        }
      }
    }
    smartboxBarImg: contentfulAsset(title: {eq: "smartbox-bar"}) {
      id
      description
      title
      fluid (maxWidth: 768, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid
      }
    }
    smartboxTrimbleImg: contentfulAsset(title: {eq: "smartbox-trimble"}) {
      id
      description
      title
      fluid (maxWidth: 768, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid
      }
    }
    smartboxBenefitsMobileImg: contentfulAsset(title: {eq: "smartbox-benefits_mobile"}) {
      id
      description
      title
      fluid (maxWidth: 768, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid
      }
    }
    smartboxBenefitsDesktopImg: contentfulAsset(title: {eq: "smartbox-benefits_desktop"}) {
      id
      description
      title
      fluid (maxWidth: 768, resizingBehavior: PAD, quality: 100) 
      {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
  }
`